import React, { type ReactElement } from 'react';

import { useEnvironment } from '~/src/contexts/environment';
import { PWLogo } from './PWLogo';
import { OmoLogo } from './OmoLogo';
import { WSLogo } from './WSLogo';

export function ProductLogo(): ReactElement {
  const { productName } = useEnvironment();

  switch (productName) {
    case 'OMO':
      return <OmoLogo width={80} height={80} />;
    case 'Wavesend':
      return <WSLogo width={80} height={80} />;
    default:
      return <PWLogo width={80} height={80} />;
  }
}
