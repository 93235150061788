import styled from 'styled-components';

import { Spacing } from '@pushwoosh/kit-constants';
import { Horizontal } from '@pushwoosh/kit-helpers';

import { ContentBox } from '~/src/components/ContentBox';

export const RootBox = styled(Horizontal)`
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const ContainerBox = styled(ContentBox)`
  > * + * {
    margin-top: ${Spacing.S7};
  }
`;
