import React, { type ReactElement } from 'react';

import { H1 } from '~/src/ui-kit';
import { MessageCard } from '~/src/components/MessageCard';

import { RootBox, ContainerBox } from './SuccessPage.styles';
import type { SuccessPageProps } from './SuccessPage.types';

export function SuccessPage(props: SuccessPageProps): ReactElement {
  const { email } = props;

  return (
    <RootBox>
      <ContainerBox>
        <H1>Check Your Email</H1>
        <MessageCard>
          You will receive a confirmation email at
          {` ${email} `}
          in a few minutes.
        </MessageCard>
      </ContainerBox>
    </RootBox>
  );
}
